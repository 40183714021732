<template>
  <div
    v-tippy="{
      content: getTooltipContent(),
      placement: 'bottom',
      allowHtml: true,
      interactive: true,
      arrow: true,
    }"
    class="ai-credits-container"
  >
    <div class="ai-credits-content">
      <div
        class="d-flex mg-b-12 align-items-center"
        style="justify-content: space-between"
      >
        <span
          class="tx-14 lh-20"
          style="font-weight: 600; font-family: Inter, sans-serif"
          >AI credits</span
        >
        <div class="tx-white tx-12 rounded-5 pd-x-8 mg-l-10 ai-credits-badge">
          {{
            user?.aiCredits && user.aiCredits > 2
              ? Number(user?.aiCredits).toLocaleString()
              : 0
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from "@/stores/user";
import { getAbsoluteUrl } from "@/utils/helperFunction";
import { storeToRefs } from "pinia";

const userStore = useUserStore();

const { user } = storeToRefs(userStore);

const getTooltipContent = () => {
  return `
    <div style='cursor: pointer; width: 248px; padding: 0.5rem 0.7rem;'>
      <h2 style='color: #ffffff; font-family: Inter, sans-serif; font-size: 12px; font-weight: 500; line-height: 18px; text-align: left;'>
        AI credits available for your team
      </h2>
      <p style='color: #ffffff; font-family: Inter, sans-serif; font-size: 12px; font-weight: 400; line-height: 16px; text-align: left;'>
        These credits are required for using features like composing email with AI, suggested replies and tone correction.
      </p>
      <a 
        style='color: #4D8BFF; font-family: Inter, sans-serif; font-size: 12px; font-weight: 500; line-height: 16px; text-align: left; margin-top: 5px;' 
        target='_blank' 
        href='${getAbsoluteUrl("/billing?p=credits")}'>
        Manage Credits
      </a>
    </div>`;
};
</script>

<style scoped>
.ai-credits-container {
  position: relative;
  background: linear-gradient(
    125.57deg,
    rgba(255, 155, 220, 0.25) 34.2%,
    rgba(158, 128, 234, 0.25) 66.92%
  );
  border-radius: 50px;
  padding: 1px;
}

.ai-credits-content {
  background: linear-gradient(
      125.57deg,
      rgba(255, 155, 220, 0.12) 34.2%,
      rgba(158, 128, 234, 0.12) 66.92%
    ),
    #ffffff;
  border-radius: 50px;
  padding: 6px 12px;
}

.ai-credits-badge {
  background: linear-gradient(
    94.64deg,
    rgba(25, 112, 252, 0.85) -14.24%,
    rgba(255, 87, 175, 0.85) 126.25%
  );
  border-radius: 5px;
}
</style>
