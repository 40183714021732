import type { EmitterEvents } from "@/functions/useMitt";
import {
  ThreadPriorityEnum,
  ThreadPriorityEnumNumeric,
} from "@/stores/enums/priority.enum";
import type {
  InboxSubType,
  InboxType,
  InboxTypeV3,
  MessageProvider,
} from "@/stores/types/inbox";
import type { NavItem } from "@/stores/types/navItem";
import type { Tag } from "@/stores/types/tag";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import type { Emitter } from "mitt";
import type { Ref } from "vue";
import type { RouteLocationNormalizedLoadedGeneric, Router } from "vue-router";
import { useInboxStore } from "@/stores/inbox";

/**
 * Removes item(s) from the passed list.
 *
 * Returns existing items array and removed items object with index positions.
 */
export const removeItemsWithCaution = <T extends { id: string | number }>(
  list: T[],
  idsToRemove: number[]
): {
  filteredList: T[];
  removedItemsObj: Record<string, T>;
} => {
  const removedItemsObj: Record<string, T> = {};
  let filteredList: T[] = [];
  // Filter out the items from the list
  // Collect the index of removed items
  filteredList = list.filter((item, idx) => {
    const itemId = typeof item.id === "string" ? parseInt(item.id) : item.id;

    if (idsToRemove.includes(itemId)) {
      removedItemsObj[idx] = item;
    } else {
      return item;
    }
  });

  return {
    filteredList,
    removedItemsObj,
  };
};

/**
 * Rollback the removed items back into the list.
 */
export const rollbackItems = <T>(
  list: T[],
  removedItemsObj: Record<string, T>
) => {
  const newList = cloneDeep(list);
  // Roll back the removed item
  for (const idx in removedItemsObj) {
    const index = parseInt(idx);
    const item = removedItemsObj[index];
    // insert the item back into its specif item.
    newList.splice(index, 0, item);
  }

  return newList;
};
export const splitSearchStr = (query: string) => {
  const matches: string[] = [];
  if (query.includes(":")) {
    const regex = /:\s*([^,\s]+)/g;
    let match: RegExpExecArray | null;

    while ((match = regex.exec(query)) !== null) {
      matches.push(match[1]);
    }
    return matches;
  } else {
    return query.split(" ");
  }
};

/**
 * Transforms the search query from "from:test,to:test1"
 * to this "squery[from]=test&squery[to]=test1"
 */
export const transformSearchQuery = (query: string, tz: string | undefined) => {
  // if single query check if it has any : to it
  // it contains then modify the query to have square bracket
  const reservedKeywords = [
    "from",
    "to",
    "cc",
    "contact",
    "body",
    "filename",
    "subject",
    "bcc",
    "start",
    "end",
    "notes",
    "status",
    "spam",
    "trash",
    "attachment",
    "tagId",
    "assignedTo",
  ];
  if (!query.includes(",")) {
    if (query.includes(":")) {
      const qItemArr = query.split(":");
      const key = qItemArr[0];
      const val = qItemArr[1];
      if (reservedKeywords.indexOf(key) == -1) {
        return `squery=${encodeURIComponent(query)}`;
      }
      const isTagIncluded = key.toLowerCase().includes("tag");

      return `squery[${key}]${isTagIncluded ? "[]" : ""}=${encodeURIComponent(
        val
      )}`;
    }

    return `squery=${encodeURIComponent(query)}`;
  }

  // if have "," but no ":" ( means no key value )
  if (query.includes(",") && !query.includes(":")) {
    return `squery=${encodeURIComponent(query)}`;
  }
  // if "," and ":" ( means key and value present )
  let transformedQuery = "";

  query.split(",").forEach((qItem) => {
    if (qItem.length) {
      let queryHasReservedKeyword = true;
      let key = "";
      let rest: string[] = [];
      if (reservedKeywords.some((keyword) => qItem.startsWith(`${keyword}:`))) {
        [key, ...rest] = qItem.split(":");
      } else {
        queryHasReservedKeyword = false;
        rest = [qItem];
      }
      let value = rest.join(":");
      if (key == "start" || key == "end") {
        if (key == "end") {
          value = dayjs(value)
            .add(23, "hour")
            .add(59, "minute")
            .add(59, "second")
            .tz(tz, true)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss");
        } else {
          value = dayjs(value).tz(tz, true).utc().format("YYYY-MM-DDTHH:mm:ss");
        }
      }
      // Handling params with multiple values, like tags TagId:12345+67890
      if (value.includes("+")) {
        let multipleValuedParam = "";

        value.split("+").forEach((v) => {
          multipleValuedParam += `&squery[${key}][]=${encodeURIComponent(v)}`;
        });

        transformedQuery += multipleValuedParam;
      } else if (!queryHasReservedKeyword) {
        if (!transformedQuery.includes("&squery=")) {
          transformedQuery += `&squery=${encodeURIComponent(value)}`;
        } else {
          transformedQuery += `${encodeURIComponent(value)}`;
        }
      } else {
        const isTagIncluded = key.toLowerCase().includes("tag");
        transformedQuery += `&squery[${key}]${
          isTagIncluded ? "[]" : ""
        }=${encodeURIComponent(value)}`;
      }
    }
  });
  return transformedQuery;
};

//test comment
export const highlightWords = (
  content: string | null | undefined,
  words: string[]
): string => {
  // Regular expression to match any word from the array
  if (content === null || content === undefined) {
    return "";
  }
  if (content === "" || words.length === 0 || words[0] === "") {
    return content;
  }
  if (content.length > 50000) {
    return content;
  }
  const regex = new RegExp(`(?<!<[^>]*)\\b(${words.join("|")})\\b`, "gi");
  // Wrap occurrences of words from the array with <mark> tags
  const replacedString = content.replace(regex, "<mark>$1</mark>");
  return replacedString;
};

/**
 * Adds opacity to hex color.
 * @param color: Hex color
 */
export const addAlpha = (color: string, opacity: number): string => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const base64Encoder = (content: string) => {
  return btoa(unescape(encodeURIComponent(content)));
};

export const base64Decoder = (content: string) => {
  return decodeURIComponent(escape(window.atob(content)));
};

/**
 * Moves item to defined index in passed arr.
 */
export const moveItemInArr = <T>(arr: T[], oldIdx: number, newIdx: number) => {
  if (newIdx >= arr.length) {
    return;
  }
  arr.splice(newIdx, 0, arr.splice(oldIdx, 1)[0]);
};

export const inboxTypeV3toInboxType = (type: InboxTypeV3): InboxType => {
  if (type == "twitter-dm") {
    return "twitterdm";
  } else if (type == "facebook-comments") {
    return "fb-feed";
  } else if (type == "facebook-dm") {
    return "facebook";
  } else if (type == "instagram-comments") {
    return "instagram";
  }
  return type;
};
export const inboxTypetoInboxTypeV3 = (type: InboxType): InboxTypeV3 => {
  if (type == "twitterdm") {
    return "twitter-dm";
  } else if (type == "fb-feed") {
    return "facebook-comments";
  } else if (type == "facebook") {
    return "facebook-dm";
  } else if (type == "instagram") {
    return "instagram-comments";
  } else if (type == "universal") {
    return "mail";
  }
  return type;
};

export const inboxTypeAndProviderToSubtype = (
  type: InboxTypeV3,
  provider?: MessageProvider
): InboxSubType => {
  if (type == "twitter-dm") {
    return "twitterdm";
  } else if (type == "facebook-comments") {
    return "fb-feed";
  } else if (type == "facebook-dm") {
    return "facebook";
  } else if (type == "instagram-comments") {
    return "instagram";
  } else if (type == "sms") {
    if (provider == "twilio" || provider == "whatsappcloud") {
      return "sms";
    } else {
      return provider ?? "sms";
    }
  } else if (type == "whatsapp") {
    if (provider == "whatsappcloud") {
      return "whatsapp-cloud";
    } else if (provider == "twilio") {
      return "sms";
    } else {
      return provider ?? "whatsapp";
    }
  }
  return type;
};

export const inboxSubTypeToMsgProvider = (
  type: InboxSubType
): MessageProvider | undefined => {
  switch (type) {
    case "twilio":
    case "justcall":
    case "dialpad":
    case "plivo":
    case "ringcentral":
    case "dialog360":
      return type;
    case "whatsapp-cloud":
      return "whatsappcloud";
    default:
      return undefined;
  }
};

export const numericPriorityToString = (
  priority: ThreadPriorityEnumNumeric
): ThreadPriorityEnum => {
  if (priority == ThreadPriorityEnumNumeric.high) {
    return ThreadPriorityEnum.high;
  } else if (priority == ThreadPriorityEnumNumeric.medium) {
    return ThreadPriorityEnum.medium;
  } else if (priority == ThreadPriorityEnumNumeric.low) {
    return ThreadPriorityEnum.low;
  } else if (priority == ThreadPriorityEnumNumeric.choose) {
    return ThreadPriorityEnum.choose;
  }
  return ThreadPriorityEnum.choose;
};

export const sanitizeEmail = (emailData: string): string => {
  let modifiedEmailData = emailData;

  try {
    // Use regular expressions to remove the unwanted attributes
    modifiedEmailData = emailData.replace(
      /<[^>]+class="trimmed-node"[^>]*>[\s\S]*?<\/[^>]+>/g,
      (match) => {
        return match.replace(
          /(?<=data-(?:content|from|date)=["'])([a-zA-Z0-9+/=]+)(?=["'])/g,
          ""
        );
      }
    );
  } catch (err) {
    // If the modified email data is the same as the original, indicating that the regular expression method failed
    if (modifiedEmailData === emailData) {
      try {
        // Convert the stringified HTML to a DOM object
        const parser = new DOMParser();
        const doc = parser.parseFromString(emailData, "text/html");

        // Get all elements with the class "trimmed-node"
        const trimmedNodes = doc.getElementsByClassName("trimmed-node");

        // Iterate over the elements and remove the unwanted attributes using DOM manipulation
        for (let i = 0; i < trimmedNodes.length; i++) {
          const node = trimmedNodes[i];
          node.removeAttribute("data-content");
          node.removeAttribute("data-date");
          node.removeAttribute("data-from");
          node.removeAttribute("data-currentinfo");
        }

        // Convert the modified DOM object back to an HTML string
        modifiedEmailData = doc.documentElement.outerHTML;
      } catch (error) {
        // If both methods fail, return the original email data as fallback
        modifiedEmailData = emailData;
      }
    }
  }

  return modifiedEmailData;
};

export const sanitizeNote = (note: string): string => {
  try {
    const $temp = $(`<div>${note}</div>`);
    // eslint-disable-next-line
    $temp.find("p:empty").html("<br/>");

    // eslint-disable-next-line
    $temp
      .find("*")
      .filter(function () {
        return $(this).children().length == 0;
      })
      .each(function (i, el) {
        let txt = $(el).text();
        const htm = $(el).parent().html();

        if (htm.indexOf("userMention") > -1) {
          return;
        }

        const tweetRe =
          /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/([0-9]{19})/g;
        txt = txt.replace(tweetRe, `<div class="hw_tweet" id="$3"></div>`);
        const emailRe =
          // eslint-disable-next-line
          /((([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))/gi;
        txt = txt.replace(emailRe, '<a href="mailto:$1">$1</a>');
        const urlRe =
          // eslint-disable-next-line
          /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=;]*))/gi;
        txt = txt.replace(urlRe, '<a target="_blank" href="$1">$1</a>');
        $(el).html(txt);
      });

    // eslint-disable-next-line
    const temp = $temp.find("div")[0];
    let lastTextNodeKey = 0;

    if (temp) {
      temp.childNodes.forEach((node, key) => {
        if (node.nodeType === 3) {
          lastTextNodeKey = key;
        }
      });

      temp.childNodes.forEach((node, key) => {
        if (key >= lastTextNodeKey) {
          const nextNode = temp.childNodes[key + 1];
          if (nextNode && nextNode.nodeName == "BR") {
            temp.removeChild(nextNode);
          }
        }
      });
    }

    // eslint-disable-next-line
    return $temp.html();
  } catch (err) {
    return note;
  }
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getAppBaseUrl: () => string = () => {
  const baseUrl = import.meta.env.VITE_APP_BASE_URL;
  return baseUrl;
};

export const getAbsoluteUrl = (relativeUrl: string) => {
  const baseUrl = getAppBaseUrl();
  return baseUrl + relativeUrl;
};

export const trimNestedTagsName = (tagNestedName: string): string => {
  if (!tagNestedName) {
    return " ";
  }
  const nestedNameArray = tagNestedName.split(">");
  if (tagNestedName.length > 15) {
    if (!tagNestedName) {
      return " ";
    }
    if (nestedNameArray.length > 2) {
      return "..>..> " + nestedNameArray[nestedNameArray?.length - 1].trim();
    } else if (nestedNameArray.length == 2) {
      return "..> " + nestedNameArray[nestedNameArray?.length - 1].trim();
    } else {
      return nestedNameArray[nestedNameArray?.length - 1].trim();
    }
  }
  return tagNestedName;
};

export const walkTagArr = (
  tags: Tag[],
  link: string,
  isPinned: boolean | undefined
): NavItem[] => {
  const inboxStore = useInboxStore();
  const tagsIdCountMap = inboxStore.inbox?.stats?.tags;
  return tags.map((tag) => {
    let children: NavItem[] = [];
    const conversationCount = tagsIdCountMap?.[tag.id]?.toString() ?? "";

    if (tag.children && tag.children.length) {
      children = walkTagArr(tag.children, link, isPinned);
    }
    return {
      text: tag.name,
      icon: "tag-outline",
      to: `${link}${tag.id}`,
      moreChildren: children,
      iconColor: tag.color,
      isPinned: isPinned ? isPinned : false,
      tagId: tag.id,
      count: conversationCount,
    };
  });
};

export const findInNestedTags = (
  tags: Tag[],
  tagId: number
): Tag | undefined => {
  let flatTag: Tag | undefined = undefined;
  function flattenTagArr(tags: Tag[]): Tag[] {
    return tags.map((tag) => {
      if (tag.children && tag.children.length) {
        flattenTagArr(tag.children);
      }
      if (tag.id == tagId) {
        flatTag = tag;
      }
      return tag;
    });
  }
  flattenTagArr(tags);
  return flatTag;
};

export type HandleSidebarRouteChangeRequest = {
  to: string;
  route: RouteLocationNormalizedLoadedGeneric;
  routeKey: Ref<number>;
  emitter: Emitter<EmitterEvents>;
  router: Router;
};

export const handleSidebarRouteChange = (
  request: HandleSidebarRouteChangeRequest
) => {
  const { route, to, routeKey, emitter, router } = request;
  const currentRoute = route.fullPath;
  if (currentRoute === to) {
    // in case of search update route key to force route update for same route
    if (route.query.search) {
      routeKey.value = Math.ceil(Math.random() * 1000);
      // run the same logic used for reload button
    } else {
      emitter.emit("inbox-view-list-header:reload-threads", {
        loadThreadsPage: true,
      });
    }
  } else {
    router.push(to);
  }
};
